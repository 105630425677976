<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay" v-if="isAuthenticated">
        <ion-content>
          <div class="menu-wrapper">
            <div class="top">
              <ion-list id="inbox-list">
                <ion-list-header>
                  <img src="assets/logo-white.svg" class="brand" />
                </ion-list-header>

                <div class="language-wrapper">
                  <ion-nav-link
                    class="language-item"
                    :class="locale == 'de' ? 'active' : ''"
                    @click="changeLanguage('de')"
                    >DE</ion-nav-link
                  >
                  <ion-nav-link
                    class="language-item"
                    :class="locale == 'fr' ? 'active' : ''"
                    @click="changeLanguage('fr')"
                    >FR</ion-nav-link
                  >
                </div>

                <ion-menu-toggle
                  :auto-hide="false"
                  v-for="(p, i) in appPages"
                  :key="i"
                >
                  <ion-item
                    @click="selectedIndex = i"
                    router-direction="root"
                    :router-link="p.url"
                    lines="none"
                    :detail="false"
                    class="hydrated"
                    :class="{ selected: selectedIndex === i }"
                  >
                    <ion-label>{{ t(p.title) }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ion-list>

              <ion-list id="labels-list">
                <ion-list-header>{{ t('mehr_ueber_maltech') }}</ion-list-header>

                <a :href="$t('vermietung_link')" target="_blank">
                  <ion-item lines="none">
                    <ion-label>{{ $t('vermietung') }}</ion-label>
                  </ion-item>
                </a>
                <a :href="$t('service_link')" target="_blank">
                  <ion-item lines="none">
                    <ion-label>{{ $t('service') }}</ion-label>
                  </ion-item>
                </a>
                <a :href="$t('schulung_link')" target="_blank">
                  <ion-item lines="none">
                    <ion-label>{{ $t('schulung') }}</ion-label>
                  </ion-item>
                </a>
                <a :href="$t('firma_link')" target="_blank">
                  <ion-item lines="none">
                    <ion-label>{{ $t('firma') }}</ion-label>
                  </ion-item>
                </a>
                <a :href="$t('news_link')" target="_blank">
                  <ion-item lines="none">
                    <ion-label>{{ $t('news') }}</ion-label>
                  </ion-item>
                </a>
              </ion-list>
              <ion-list id="labels-list">
                <ion-list-header>{{
                  $t('das_koennte_sie_interessieren')
                }}</ion-list-header>
                <a
                  href="https://www.youtube.com/channel/UCd8R48LwFdxokhxzZEJgUrQ/playlists"
                  target="_blank"
                >
                  <ion-item lines="none">
                    <ion-icon :icon="logoYoutube" slot="start"></ion-icon>
                    <ion-label>YouTube</ion-label>
                  </ion-item>
                </a>

                <a
                  v-if="deviceInfo && deviceInfo.operatingSystem == 'ios'"
                  href="https://apps.apple.com/app/ipaf-epal/id1557633308?utm_campaign=epal&utm_medium=store-logos&utm_source=ipaf-epal-store-logos"
                  target="_blank"
                >
                  <ion-item lines="none">
                    <ion-icon :icon="idCard" slot="start"></ion-icon>
                    <ion-label>IPAF ePAL</ion-label>
                  </ion-item>
                </a>

                <a
                  v-else
                  href="https://play.google.com/store/apps/details?id=org.ipaf.epal&utm_campaign=epal&utm_medium=store-logos&utm_source=ipaf-epal-store-logos"
                  target="_blank"
                >
                  <ion-item lines="none">
                    <ion-icon :icon="idCard" slot="start"></ion-icon>
                    <ion-label>IPAF ePAL</ion-label>
                  </ion-item>
                </a>

                <a
                  href="https://maltech.ipaf-pal.org/accounts/login/?next=%2F"
                  target="_blank"
                >
                  <ion-item lines="none">
                    <ion-icon :icon="school" slot="start"></ion-icon>
                    <ion-label>IPAF E-Learning</ion-label>
                  </ion-item>
                </a>
              </ion-list>
            </div>

            <div class="bottom" @click="logout">
              <ion-item lines="none"
                >{{ $t('abmelden') }}
                <ion-icon slot="end" :icon="logOut"></ion-icon>
              </ion-item>
            </div>
          </div>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script lang="ts">
import { logOut, idCard, school } from 'ionicons/icons';
import {
  IonApp,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonIcon,
  IonNavLink,
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import store from './store';
import { Device } from '@capacitor/device';
import { logoYoutube } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonIcon,
    IonNavLink,
  },

  mounted() {
    Device.getLanguageCode().then((res) => {
      if (res.value.includes('-')) {
        const language = res.value.split('-')[0];
        this.$i18n.locale = language;
      } else {
        this.$i18n.locale = res.value;
      }
    });
  },

  setup() {
    const selectedIndex = ref(0);
    const deviceInfo = ref();

    const { t, locale } = useI18n();

    const getDeviceInfo = async () => {
      deviceInfo.value = await Device.getInfo();
    };

    getDeviceInfo();

    const appPages = [
      {
        title: 'mein_konto',
        url: '/mein-konto',
      },
      {
        title: 'teilnahmen',
        url: '/teilnahmen',
      },
    ];

    const path = window.location.pathname;
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        (page) => page.url.toLowerCase() == path.toLowerCase()
      );
    }

    const route = useRoute();

    const logout = function () {
      store.commit('logout');
    };

    const changeLanguage = function (lang) {
      locale.value = lang;
    };

    return {
      selectedIndex,
      appPages,
      logOut,
      deviceInfo,
      logoYoutube,
      idCard,
      school,
      logout,
      t,
      locale,
      changeLanguage,
      isSelected: (url: string) => (url === route.path ? 'selected' : ''),
    };
  },

  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated;
    },
  },
});
</script>

<style scoped lang="scss">
.brand {
  max-height: 20px;
  margin-bottom: 1rem;
}

ion-split-pane {
  --side-max-width: 180px;
  --border: none;
}

ion-menu ion-content {
  --background: linear-gradient(180deg, #303a4f, #2a3345);

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    ion-icon {
      color: #fff;
      margin-right: 1rem;
    }

    .top {
      padding-top: 0;
    }
    .bottom {
      background: #323c52;
      ion-item {
        --color: #fff;
        cursor: pointer;
        --background: #323c52;
        padding-left: 20px;
        padding-top: 5px;
        padding-bottom: 10px;
        font-weight: bold;
        ion-icon {
          margin-right: 0;
        }
      }
    }
  }
  ion-list {
    background: transparent;
    ion-item {
      color: #fff;
      --background: transparent;
      padding: 10px 20px;
      cursor: pointer;
      &.selected {
        background: #384257;
      }
    }
  }

  .labels-list {
    ion-item {
      margin-bottom: none;
    }
  }
}

a {
  text-decoration: none;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu ion-list-header {
  padding-left: 35px;
  color: #94a7b7;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-label {
  font-weight: 600;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

.language-wrapper {
  padding-left: 35px;

  padding-bottom: 1rem;
  .language-item {
    margin-right: 1rem;
    font-weight: normal;
    cursor: pointer;
    color: #fff;
    font-size: 1rem;
    &.active {
      font-weight: 800;
    }
  }
}

@media screen and (max-width: 991px) {
  .brand {
    margin-top: 1.5rem;
  }

  ion-menu {
    ion-list-header {
      font-size: 1rem;
    }

    ion-content ion-list ion-item {
      padding: 10px 15px;
    }
  }
}
</style>
