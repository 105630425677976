export const globalizationList = {
  de: {
    impressum: 'Impressum',
    datenschutz: 'Datenschutz',
    agb: 'AGB',
    impressum_url: 'https://maltech.ch/de/impressum/',
    datenschutz_url: 'https://maltech.ch/de/datenschutz/',
    agb_url: 'https://maltech.ch/de/agb/',
    anmelden: 'Anmelden',
    alle_ihre_kurse_auf_einen_blick: 'Alle Ihre Kurse auf einen Blick.',
    alternative_anmeldung: 'Alternative Anmeldung',
    email: 'E-Mail',
    alternative_email: 'Alternative E-Mail',
    passwort: 'Passwort',
    login: 'Login',
    anrede: 'Anrede',
    vorname: 'Vorname',
    nachname: 'Nachname',
    geburtsdatum: 'Geburtsdatum',
    maltech_card_nummer: 'Maltech Card Nummer',
    passwort_vergessen: 'Passwort vergessen?',
    passwort_anfordern: 'Passwort anfordern',
    ihr_passwort_wird_an_die_hinterlegte_email_adresse_zugestellt:
      'Ihr Passwort wird an die hinterlegte E-Mail Adresse zugestellt.',
    kontaktieren_sie_uns: 'Kontaktieren Sie uns',
    bei_fragen_oder_unklarheiten: 'Bei Fragen oder Unklarheiten',
    mehr_ueber_maltech: 'Mehr über Maltech',
    zurueck_zum_login: 'Zurück zum Login',
    mein_konto: 'Mein Konto',
    abmelden: 'Abmelden',
    vermietung: 'Vermietung',
    vermietung_link: 'https://maltech.ch/de/vermietung/',
    service: 'Service',
    service_link: 'https://maltech.ch/de/service/',
    schulung: 'Schulung',
    schulung_link: 'https://maltech.ch/de/schulung/',
    firma: 'Firma',
    firma_link: 'https://maltech.ch/de/firma/',
    news: 'News',
    news_link: 'https://maltech.ch/de/news/',
    das_koennte_sie_interessieren: 'Das könnte Sie Interessieren',
    ipaf_ablaufdatum: 'IPAF-Ablaufdatum',
    pal_card_nr: 'PAL Card Nr.',
    maltech_card: 'Maltech Card',
    maltech_card_ausstelldatum: 'Maltech Card Ausstelldatum',
    mobile: 'Mobile',
    mobile_hinweis: '(mit Landesvorwahl z.B. +41781234567)',
    sprachkenntnisse_deutsch: 'Sprachkenntnisse in Deutsch',
    unternehmen: 'Unternehmen',
    beruf: 'Beruf',
    funktion: 'Funktion',
    speichern: 'Speichern',
    anschrift: 'Anschrift',
    strasse: 'Strasse',
    plz: 'PLZ',
    ort: 'Ort',
    land: 'Land',
    adresse_plz_oder_ort_eingeben: 'Adresse, PLZ oder Ort eingeben',
    fuehrerschein_nr: 'Führerschein Nr.',
    teilnahmen: 'Teilnahmen',
    gut: 'Gut',
    mittel: 'Mittel',
    genuegend: 'Genügend',
    hebebuehnen_erfahrung: 'Hebebühnen-Erfahrung',
    taeglich: 'Täglich',
    woechentlich: 'Wöchentlich',
    monatlich: 'Monatlich',
    jaehrlich: 'Jährlich',
    ziel: 'Ziel',
    beschreibung: 'Beschreibung',
    vorbereitung: 'Vorbereitung',
    ausschreibung: 'Ausschreibung',
    kursdokument: 'Kursdokument',
    unterlagen: 'Unterlagen',
    lageplan: 'Lageplan',
    tag: 'Tag',
    tage: 'Tage',
    zugangsdaten_nicht_gefunden_bitte_kontaktieren_sie_uns:
      'Zugangsdaten nicht gefunden - Bitte kontaktieren Sie uns.',
    uebermittlung_erfolgreich: 'Übermittlung erfolgreich',
    ihre_daten_wurden_angepasst: 'Ihre Daten wurden angepasst',
    ein_fehler_ist_aufgetreten: 'Ein Fehler ist aufgetreten',
    fuellen_sie_alle_pflichfelder_aus: 'Füllen Sie alle Pflichtfelder aus',
    ['Pflichtfeld darf nicht leer sein']: 'Pflichtfeld darf nicht leer sein',
    termine: 'Termine',
    weitere_dokumente: 'Weitere Dokumente',
    zertifikat: 'Zertifikat',
    schweiz: 'Schweiz',
    liechtenstein: 'Liechtenstein',
    österreich: 'Österreich',
    deutschland: 'Deutschland',
    italien: 'Italien',
    frankreich: 'Frankreich',
  },
  fr: {
    impressum: 'Impressum',
    datenschutz: 'Protection de données',
    agb: 'CGV',
    impressum_url: 'https://maltech.ch/fr/impressun/',
    datenschutz_url: 'https://maltech.ch/fr/datenschutz/',
    agb_url: 'https://maltech.ch/fr/cgv',
    anmelden: 'Inscription',
    alle_ihre_kurse_auf_einen_blick: "Tous les cours en un coup d'œil.",
    alternative_anmeldung: 'Inscription alternative',
    email: 'E-mail',
    alternative_email: 'E-mail alternatif',
    passwort: 'Mot de passe',
    login: 'Login',
    anrede: 'Titre',
    vorname: 'Prénom',
    nachname: 'Nom de famille',
    geburtsdatum: 'Date de naissance',
    maltech_card_nummer: 'Maltech Card No.',
    passwort_vergessen: 'Mot de passe oublié?',
    passwort_anfordern: 'Demander un mot de passe',
    ihr_passwort_wird_an_die_hinterlegte_email_adresse_zugestellt:
      "Votre mot de passe sera envoyé à l'adresse e-mail enregistrée.",
    mehr_ueber_maltech: 'Pour en savoir plus',
    kontaktieren_sie_uns: 'Contactez-nous',
    bei_fragen_oder_unklarheiten: 'Si vous avez des questions ou des doutes',
    zurueck_zum_login: 'Retour au login',
    mein_konto: 'Mon compte',
    abmelden: 'Se désinscrire',
    vermietung: 'Location',
    vermietung_link: 'https://maltech.ch/fr/location/',
    service: 'Service',
    service_link: 'https://maltech.ch/fr/service/',
    schulung: 'Formation',
    schulung_link: 'https://maltech.ch/fr/formation/',
    firma: 'Société',
    firma_link: 'https://maltech.ch/fr/societe/',
    news: 'News',
    news_link: 'https://maltech.ch/fr/news/',
    das_koennte_sie_interessieren: 'Cela pourrait vous intéresser',
    ipaf_ablaufdatum: 'Echéance IPAF',
    pal_card_nr: 'No. IPAF',
    maltech_card: 'Maltech Card',
    maltech_card_ausstelldatum: "Maltech Card Date d'émission",
    mobile: 'Téléphone mob.',
    mobile_hinweis: "(avec l'indicatif du pays, par ex. +41781234567)",
    sprachkenntnisse_deutsch: 'Connaissance en langue',
    unternehmen: 'Entreprise',
    beruf: 'Profession',
    funktion: 'Fonction',
    speichern: 'Sauver',
    anschrift: 'Adresse',
    strasse: 'Adresse',
    plz: 'Code postal',
    ort: 'Lieu',
    land: 'Pays',
    adresse_plz_oder_ort_eingeben:
      "Saisir l'adresse, le code postal ou le lieu",
    fuehrerschein_nr: 'Permis de conduire',
    teilnahmen: 'Participations',
    gut: 'Bien',
    mittel: 'Moyen',
    genuegend: 'Suffisant',
    hebebuehnen_erfahrung: 'Expérience plateforme élévatrice',
    taeglich: 'Tous les jours',
    woechentlich: 'Hebdomadaire',
    monatlich: 'Mensuel',
    jaehrlich: 'Annuel',
    ziel: 'Objectifs',
    beschreibung: 'Description',
    vorbereitung: 'Préparation',
    ausschreibung: "Appel d'offres",
    kursdokument: 'Document de cours',
    unterlagen: 'Documents',
    lageplan: "Plan d'implantation",
    tag: 'Jour',
    tage: 'Jours',
    zugangsdaten_nicht_gefunden_bitte_kontaktieren_sie_uns:
      "Données d'accès non trouvées - Veuillez nous contacter.",
    uebermittlung_erfolgreich: 'Transmission réussie',
    ihre_daten_wurden_angepasst: 'Vos données ont été ajustées',
    ein_fehler_ist_aufgetreten: 'Une erreur est survenue',
    fuellen_sie_alle_pflichfelder_aus: 'Füllen Sie alle Pflichtfelder aus',
    ['Pflichtfeld darf nicht leer sein']:
      'Le champ obligatoire ne peut pas être vide',
    termine: 'Dates',
    weitere_dokumente: 'Autres documents',
    zertifikat: 'Certificat',
    schweiz: 'Schweiz',
    liechtenstein: 'Liechtenstein',
    österreich: 'Autriche',
    deutschland: 'Allemagne',
    italien: 'Italie',
    frankreich: 'France',
  },
};
